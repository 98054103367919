import {useEffect, useState} from "react";
import StoryIdeaElement from "../components/StoryIdeaElement";

export default function StoryIdeas(){
    const [storyIdeas, setStoryIdea] = useState([])

    async function getStoryIdeas(){
        const response = await fetch("https://community-story-backend.onrender.com/story-ideas",{
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        })
        if(!response.ok){
            console.log(response.json())
        }
        const data = await response.json();
        setStoryIdea(data);
    }

    useEffect(()=>{
        getStoryIdeas();
    }, [])

    //[{"_id":"642b4ec4609a2cc67469f224","name":"Maik Steffan","maxCycles":20,"currentCycle":1,"wordsAllowed":10,"nextCycle":"2023-04-03T22:40:12.000Z","likes":0,"currentStory":"Es war einmal ein Mann","storySuggestions":[],"__v":0}]
    return(
        <div>
            <h1>Story Ideas V1.01</h1>
            <div>
                {storyIdeas.map(
                    (storyIdea) => <StoryIdeaElement
                        key={storyIdea['_id']}
                        name={storyIdea.name}
                        maxCycles={storyIdea.maxCycles}
                        currentCycle={storyIdea.currentCycle}
                        wordsAllowed={storyIdea.wordsAllowed}
                        nextCycle={storyIdea.nextCycle}
                        likes = {storyIdea.likes}
                        currentStory ={storyIdea.currentStory}
                        storySuggestions={storyIdea.storySuggestions}/>
                )}
            </div>
            <button>Idee hinzufügen</button>
        </div>
    )
}
