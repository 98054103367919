import style from "./App.css"
import StoryIdeas from "./pages/StoryIdeas";
import {Navigate, Route, Routes} from "react-router-dom";
import AddIdea from "./pages/AddIdea";

function App() {
  return (
    <div>
        <main className={style["background"]}>
            <Routes>
                <Route path='*' element={<Navigate to='/story-ideas'/>}/>
                <Route path='/story-ideas' element={<StoryIdeas/>}/>
                <Route path='/add' element={<AddIdea/>}/>
                <Route path='/write-story/:id' element={<StoryIdeas/>}/>
            </Routes>
        </main>
    </div>
  );
}

export default App;
