import style from "./StoryIdeaElement.module.css"

export default function StoryIdeaElement(props){
    return(
        <div className={style["grid-container"]}>
            <div className={style["name"]}>{props.name}</div>
            <div className={style["main-content"]}>
                <div className={style["story"]}>{props.currentStory}</div>
                <div className={style["like"]}>{props.likes}</div>
                <div className={style["timeline"]}>{props.nextCycle}</div>
            </div>

            <div className={style["stats"]}>
                <p>{props.maxCycles}</p>
                <p>{props.wordsAllowed}</p>
                <p>{props.nextCycle}</p>
            </div>
        </div>
    )
}
